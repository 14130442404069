<template>
  <div v-motion :initial="{
    opacity: 0.1,
  }" :enter="{

    opacity: 0.8,
    transition: {
      duration: 500,
      ease: 'easeIn',
    },
  }">
    <div class="md:w-[100%] hidden lg:block lg:w-[90%] xl:w-[1280px] mx-auto  xl:pb-10 xl:px-5 xl:mt-5">
      <div class="flex justify-between gap-[40px] items-center">
        <div class="w-[50%] py-4 space-y-4">
          <div class="">

            <div>
              <p class="uppercase text-gray-500 font-poppins text-sm">introduction</p>
              <h2
                class="xl:text-[35px] text-start inline-block font-bold text-primery1 lg:text-Heading1 md:text-heading2 xl:border-b-4 xl:border-primery1  font-NotoSansKhmer">
                អំពីពួកយើង
              </h2>
            </div>

            <p style="line-height: 1.7" class="text-[16px] xl:text-[20px] xl:mt-[15px] text-justify">
              ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម (Master-IT System) គឺជាក្រុមហ៊ុនផ្តល់ដំណោះស្រាយផ្នែកបច្ចេកវិទ្យា
              ដែលមានឯកទេសក្នុងការអភិវឌ្ឍន៍ប្រព័ន្ធគ្រប់គ្រងទិន្នន័យសម្រាប់អាជីវកម្ម (Software Development)
              ការរចនាគេហទំព័រ (Web Design) និងការប្រឹក្សាយោបល់រៀបចំប្រព័ន្ធគ្រប់គ្រងទិន្នន័យសម្រាប់អាជីវកម្ម (Business
              Software Consultant) ។
            </p>
          </div>
          <div>
            <!-- <img class="w-full" src="https://st2.depositphotos.com/3591429/6006/i/450/depositphotos_60063963-stock-photo-people-discussing-about-us.jpg" alt=""> -->
            <img class="w-full border-[1px] rounded-md" src="../../src/assets/undraw_About_us_page_re_2jfm.png" alt="">

          </div>
        </div>
        <div class="w-[40%] xl:space-y-5">


          <div class="flex items-center gap-5 lg:my-7 xl:my-0">
            <div
              class="bg-gradient-to-l from-[#500192] to-indigo-700 lg:w-[125px] lg:h-[90px] xl:w-[130px] xl:h-[100px] flex justify-center items-center  shadow-x rounded-lg shadow-xl">
              <img src="../../src/assets/opportunity.png" class="lg:w-[40px] xl:w-[50px]" alt="">
            </div>
            <div class="w-full">
              <div class="text-start lg:space-y-1 xl:space-y-0 ">
                <h3
                  class="text-start md:my-5 lg:my-0 lg:pt-10 xl:pt-16  ml-2 my-3 xl:my-0  xl:text-[28px] lg:text-heading2 font-bold text-[20px] text-primery1 font-NotoSansKhmer">
                  ទស្សនៈវិស័យ
                </h3>
                <p
                  class="text-[16px] lg:ml-2 xl:ml-0 md:text-[20px] lg:text-[16px]  xl:text-[18px] text-black/70 font-[400] space-y-5  font-NotoSansKhmer lg:text-justify xl:text-start xl:p-2">
                  យើងជឿជាក់ថា កត្តាដ៏សំខាន់មួយជួយធ្វើឱ្យអាជីវកម្ម និងប្រទេសជាតិរីកចម្រើនលឿន គឺកត្តាជឿនលឿននៃបច្ចេកវិទ្យា
                  ម៉ាស្ទ័រអាយធីស៊ីស្ទឹម នឹងក្លាយជាក្រុមហ៊ុនបច្ចេកវិទ្យា ឈានមុខគេបង្កើតមោតទនភាពជូនប្រជាជនកម្ពុជា។

                </p>
              </div>
            </div>
          </div>

          <div class="flex items-center gap-5 lg:my-7 xl:my-0">
            <div
              class="bg-gradient-to-l from-[#500192] to-indigo-700 lg:w-[125px] lg:h-[90px] xl:w-[130px] xl:h-[100px] flex justify-center items-center  shadow-x rounded-lg shadow-xl">
              <img src="../../src/assets/mission-accomplished.png" class="lg:w-[40px] xl:w-[50px]" alt="">
            </div>
            <div class="w-full">
              <div class="text-start lg:space-y-1 xl:space-y-0 ">
                <h3
                  class="text-start md:my-5 lg:my-0 lg:pt-16 xl:pt-16  ml-2 my-3 xl:my-0  xl:text-[28px] lg:text-heading2 font-bold text-[20px] text-primery1 font-NotoSansKhmer">
                  បេសកម្ម
                </h3>
                <p
                  class="text-[16px] lg:ml-2 xl:ml-0 md:text-[20px] lg:text-[16px]  xl:text-[18px] text-black/70 font-[400] space-y-5  font-NotoSansKhmer lg:text-justify xl:text-start xl:p-2">
                  នាំមកនូវប្រព័ន្ធបច្ចេកវិទ្យាថ្មី ឈានមុខគេសម្រាប់គ្រប់អាជីវកម្ម និងប្រទេសកម្ពុជា
                  តាមរយៈការរួមគ្នាបន្តការស្រាវជ្រាវ បន្តការអភិវឌ្ឍន៍ឥតឈប់ឈរ បន្តការផ្ដល់ជូននូវបទពិសោធន៍ប្រើប្រាស់
                  និងសេវាកម្មដ៏ល្អជាទីទុកចិត្ត ពេញចិត្តជូនអតិថិជនគ្រប់កាលៈទេសៈ។

                </p>
              </div>
            </div>
          </div>


          <div class="flex items-center gap-5 lg:my-7 xl:my-0">
            <div
              class="bg-gradient-to-l from-[#500192] to-indigo-700 lg:w-[125px] lg:h-[90px] xl:w-[130px] xl:h-[100px] flex justify-center items-center  shadow-x rounded-lg shadow-xl">
              <img src="../../src/assets/value.png" class="lg:w-[40px] xl:w-[50px]" alt="">
            </div>
            <div class="w-full">
              <div class="text-start lg:space-y-1 xl:space-y-0 ">
                <h3
                  class="text-start md:my-5 lg:my-0 lg:pt-10 xl:pt-10  ml-2 my-3 xl:my-0  xl:text-[28px] lg:text-heading2 font-bold text-[20px] text-primery1 font-NotoSansKhmer">
                  គុណតម្លៃ
                </h3>
                <p
                  class="text-[16px] lg:ml-2 xl:ml-0 md:text-[20px] lg:text-[16px]  xl:text-[18px] text-black/70 font-[400] space-y-5  font-NotoSansKhmer lg:text-justify xl:text-start xl:p-2">
                  យើងប្រកាន់ខ្ជាប់នូវការអនុវត្តនីតិវិធីប្រតិបត្តការស្តង់ដារ ដើម្បីសម្រេចឱ្យបាននូវភាពល្អឯក លើគុណភាព,
                  ប្រសិទ្ធភាព, សុវត្ថិភាព, ភាពស្តង់ដារ និងសេចក្តីថ្លៃថ្នូ សម្រាប់អតិថិជននិងគ្រប់អ្នកពាក់ព័ន្ធរបស់យើង។

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- ipad  -->
    <div class="px-10 hidden md:block  lg:hidden">
      <div class="w-full py-4 space-y-4">
        <div class="flex justify-center my-5">

          <div>
            <p class="uppercase text-gray-500 font-poppins text-sm">introduction</p>
            <h2
              class="xl:text-[35px] text-start inline-block font-bold text-primery1 lg:text-Heading1 md:text-[28px] xl:border-b-4 xl:border-primery1  font-NotoSansKhmer">
              អំពីពួកយើង
            </h2>
          </div>
        </div>
        <div>
          <!-- <img class="w-full" src="https://st2.depositphotos.com/3591429/6006/i/450/depositphotos_60063963-stock-photo-people-discussing-about-us.jpg" alt=""> -->
          <img class="w-full h-[300px] border-[1px] rounded-md object-contain"
            src="../../src/assets/undraw_About_us_page_re_2jfm.png" alt="">

        </div>

        <p style="line-height: 1.7" class="text-[20px] xl:text-[20px] xl:mt-[15px] ">
          ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម (Master-IT System) គឺ ជាក្រុមហ៊ុនផ្តល់ដំណោះស្រាយផ្នែកបច្ចេកវិទ្យា
          ដែលមានឯកទេសក្នុងការអភិវឌ្ឍន៍ប្រព័ន្ធគ្រប់គ្រងទិន្នន័យសម្រាប់អាជីវកម្ម (Software Development)
          ការរចនាគេហទំព័រ (Web Design) និងការប្រឹក្សាយោបល់រៀបចំប្រព័ន្ធគ្រប់គ្រងទិន្នន័យសម្រាប់អាជីវកម្ម (Business
          Software Consultant) ។
        </p>
      </div>


      <div class=" xl:space-y-5 mb-10 mt-5 space-y-7">

        <div class="flex   gap-10 lg:my-7 xl:my-0   bg-white px-10 py-8 rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;">
          <div
            class="bg-gradient-to-l from-[#500192] to-indigo-700 lg:w-[125px] w-[130px] h-[100px] flex justify-center items-center  shadow-x rounded-lg shadow-xl">
            <img src="../../src/assets/opportunity.png" class="w-[50px]" alt="">
          </div>
          <div class="w-full">
            <div class="text-start space-y-2 ">
              <h3
                class="text-start  xl:text-[28px] lg:text-heading2 font-bold text-[24px] text-primery1 font-NotoSansKhmer">
                ទស្សនៈវិស័យ
              </h3>
              <p
                class="text-[16px] lg:ml-2 xl:ml-0 md:text-[20px] lg:text-[16px]  xl:text-[18px] text-black/70 font-[400] space-y-5  font-NotoSansKhmer lg:text-justify xl:text-start xl:p-2">
                យើងជឿជាក់ថា កត្តាដ៏សំខាន់មួយជួយធ្វើឱ្យអាជីវកម្ម និងប្រទេសជាតិរីកចម្រើនលឿន គឺកត្តាជឿនលឿននៃបច្ចេកវិទ្យា
                ម៉ាស្ទ័រអាយធីស៊ីស្ទឹម នឹងក្លាយជាក្រុមហ៊ុនបច្ចេកវិទ្យា ឈានមុខគេបង្កើតមោតទនភាពជូនប្រជាជនកម្ពុជា។

              </p>
            </div>
          </div>


        </div>




        <div class="flex   gap-10 lg:my-7 xl:my-0   bg-white px-10 py-8 rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;">
          <div
            class="bg-gradient-to-l from-[#500192] to-indigo-700 lg:w-[125px] w-[130px] h-[100px] flex justify-center items-center  shadow-x rounded-lg shadow-xl">
            <img src="../../src/assets/mission-accomplished.png" class="w-[50px]" alt="">
          </div>
          <div class="w-full">
            <div class="text-start space-y-2 ">
              <h3
                class="text-start  xl:text-[28px] lg:text-heading2 font-bold text-[24px] text-primery1 font-NotoSansKhmer">
                បេសកម្ម
              </h3>
              <p
                class="text-[16px] lg:ml-2 xl:ml-0 md:text-[20px] lg:text-[16px]  xl:text-[18px] text-black/70 font-[400] space-y-5  font-NotoSansKhmer lg:text-justify xl:text-start xl:p-2">
                នាំមកនូវប្រព័ន្ធបច្ចេកវិទ្យាថ្មី ឈានមុខគេសម្រាប់គ្រប់អាជីវកម្ម និងប្រទេសកម្ពុជា
                តាមរយៈការរួមគ្នាបន្តការស្រាវជ្រាវ បន្តការអភិវឌ្ឍន៍ឥតឈប់ឈរ បន្តការផ្ដល់ជូននូវបទពិសោធន៍ប្រើប្រាស់
                និងសេវាកម្មដ៏ល្អជាទីទុកចិត្ត ពេញចិត្តជូនអតិថិជនគ្រប់កាលៈទេសៈ។
              </p>
            </div>
          </div>



        </div>



        <div class="flex   gap-10 lg:my-7 xl:my-0   bg-white px-10 py-8 rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;">
          <div
            class="bg-gradient-to-l from-[#500192] to-indigo-700 lg:w-[125px] w-[130px] h-[100px] flex justify-center items-center  shadow-x rounded-lg shadow-xl">
            <img src="../../src/assets/value.png" class="w-[50px]" alt="">
          </div>
          <div class="w-full">
            <div class="text-start space-y-2 ">
              <h3
                class="text-start  xl:text-[28px] lg:text-heading2 font-bold text-[24px] text-primery1 font-NotoSansKhmer">
                គុណតម្លៃ
              </h3>
              <p
                class="text-[16px] lg:ml-2 xl:ml-0 md:text-[20px] lg:text-[16px]  xl:text-[18px] text-black/70 font-[400] space-y-5  font-NotoSansKhmer lg:text-justify xl:text-start xl:p-2">
                យើងប្រកាន់ខ្ជាប់នូវការអនុវត្តនីតិវិធីប្រតិបត្តការស្តង់ដារ ដើម្បីសម្រេចឱ្យបាននូវភាពល្អឯក លើគុណភាព,
                ប្រសិទ្ធភាព, សុវត្ថិភាព, ភាពស្តង់ដារ និងសេចក្តីថ្លៃថ្នូ សម្រាប់អតិថិជននិងគ្រប់អ្នកពាក់ព័ន្ធរបស់យើង។
              </p>
            </div>
          </div>



        </div>


      </div>

    </div>

    <!-- small screen   -->

    <div class="px-10  md:hidden lg:hidden">
      <div class="w-full py-4 space-y-4">
        <div class="flex justify-center my-5">

          <div>
            <p class="uppercase text-gray-500 font-poppins text-sm">introduction</p>
            <h2
              class="xl:text-[35px] text-start inline-block font-bold text-primery1 lg:text-Heading1 text-[28px] xl:border-b-4 xl:border-primery1  font-NotoSansKhmer">
              អំពីពួកយើង
            </h2>
          </div>
        </div>
        <div>
          <!-- <img class="w-full" src="https://st2.depositphotos.com/3591429/6006/i/450/depositphotos_60063963-stock-photo-people-discussing-about-us.jpg" alt=""> -->
          <img class="w-full h-[300px] border-[1px] rounded-md object-contain"
            src="../../src/assets/undraw_About_us_page_re_2jfm.png" alt="">

        </div>

        <p style="line-height: 1.7" class="text-[18px] xl:text-[20px] xl:mt-[15px] ">
          ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម (Master-IT System) គឺ ជាក្រុមហ៊ុនផ្តល់ដំណោះស្រាយផ្នែកបច្ចេកវិទ្យា
          ដែលមានឯកទេសក្នុងការអភិវឌ្ឍន៍ប្រព័ន្ធគ្រប់គ្រងទិន្នន័យសម្រាប់អាជីវកម្ម (Software Development)
          ការរចនាគេហទំព័រ (Web Design) និងការប្រឹក្សាយោបល់រៀបចំប្រព័ន្ធគ្រប់គ្រងទិន្នន័យសម្រាប់អាជីវកម្ម (Business
          Software Consultant) ។
        </p>
      </div>


      <div class=" xl:space-y-5 mb-10 mt-10 space-y-7 relative">

        <div class="   gap-10 lg:my-7 xl:my-0   bg-white px-10 py-14 rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;">
          <div
            class="absolute -top-8 left-5  bg-gradient-to-l from-[#500192] to-indigo-700  w-[80px] h-[70px] flex justify-center items-center  shadow-x rounded-lg shadow-xl">
            <img src="../../src/assets/opportunity.png" class="w-[40px]" alt="">
          </div>
          <div class="w-full">
            <div class="text-start space-y-2 ">
              <h3
                class="text-start  xl:text-[28px] lg:text-heading2 font-bold text-[22px] text-primery1 font-NotoSansKhmer">
                ទស្សនៈវិស័យ
              </h3>
              <p
                class="text-[17px] lg:ml-2 xl:ml-0 md:text-[20px] lg:text-[16px]  xl:text-[18px] text-black/70 font-[400] space-y-5  font-NotoSansKhmer lg:text-justify xl:text-start xl:p-2">
                យើងជឿជាក់ថា កត្តាដ៏សំខាន់មួយជួយធ្វើឱ្យអាជីវកម្ម និងប្រទេសជាតិរីកចម្រើនលឿន គឺកត្តាជឿនលឿននៃបច្ចេកវិទ្យា
                ម៉ាស្ទ័រអាយធីស៊ីស្ទឹម នឹងក្លាយជាក្រុមហ៊ុនបច្ចេកវិទ្យា ឈានមុខគេបង្កើតមោតទនភាពជូនប្រជាជនកម្ពុជា។

              </p>
            </div>
          </div>
        </div>
      </div>


      <div class=" xl:space-y-5 mb-10 mt-14 space-y-7 relative">

        <div class="   gap-10 lg:my-7 xl:my-0   bg-white px-10 py-14 rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;">
          <div
            class="absolute -top-8 left-5  bg-gradient-to-l from-[#500192] to-indigo-700  w-[80px] h-[70px] flex justify-center items-center  shadow-x rounded-lg shadow-xl">
            <img src="../../src/assets/mission-accomplished.png" class="w-[40px]" alt="">
          </div>
          <div class="w-full">
            <div class="text-start space-y-2 ">
              <h3
                class="text-start  xl:text-[28px] lg:text-heading2 font-bold text-[22px] text-primery1 font-NotoSansKhmer">
                បេសកម្ម
              </h3>
              <p
                class="text-[17px] lg:ml-2 xl:ml-0 md:text-[20px] lg:text-[16px]  xl:text-[18px] text-black/70 font-[400] space-y-5  font-NotoSansKhmer lg:text-justify xl:text-start xl:p-2">
                នាំមកនូវប្រព័ន្ធបច្ចេកវិទ្យាថ្មី ឈានមុខគេសម្រាប់គ្រប់អាជីវកម្ម និងប្រទេសកម្ពុជា
                តាមរយៈការរួមគ្នាបន្តការស្រាវជ្រាវ បន្តការអភិវឌ្ឍន៍ឥតឈប់ឈរ បន្តការផ្ដល់ជូននូវបទពិសោធន៍ប្រើប្រាស់
                និងសេវាកម្មដ៏ល្អជាទីទុកចិត្ត ពេញចិត្តជូនអតិថិជនគ្រប់កាលៈទេសៈ។

              </p>
            </div>
          </div>
        </div>
      </div>



      <div class=" xl:space-y-5 mb-10 mt-16 space-y-7 relative">

        <div class="   gap-10 lg:my-7 xl:my-0   bg-white px-10 py-14 rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;">
          <div
            class="absolute -top-8 left-5  bg-gradient-to-l from-[#500192] to-indigo-700  w-[80px] h-[70px] flex justify-center items-center  shadow-x rounded-lg shadow-xl">
            <img src="../../src/assets/value.png" class="w-[40px]" alt="">
          </div>
          <div class="w-full">
            <div class="text-start space-y-2 ">
              <h3
                class="text-start  xl:text-[28px] lg:text-heading2 font-bold text-[22px] text-primery1 font-NotoSansKhmer">
                គុណតម្លៃ
              </h3>
              <p
                class="text-[17px] lg:ml-2 xl:ml-0 md:text-[20px] lg:text-[16px]  xl:text-[18px] text-black/70 font-[400] space-y-5  font-NotoSansKhmer lg:text-justify xl:text-start xl:p-2">
                យើងប្រកាន់ខ្ជាប់នូវការអនុវត្តនីតិវិធីប្រតិបត្តការស្តង់ដារ ដើម្បីសម្រេចឱ្យបាននូវភាពល្អឯក លើគុណភាព,
                ប្រសិទ្ធភាព, សុវត្ថិភាព, ភាពស្តង់ដារ និងសេចក្តីថ្លៃថ្នូ សម្រាប់អតិថិជននិងគ្រប់អ្នកពាក់ព័ន្ធរបស់យើង។

              </p>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>