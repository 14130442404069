<template>
    <div class="select-none hidden lg:block text_navbar">
        <div class="flex justify-between p-5">
            <div class="space-y-3">
                <h4 class="font-bold">សេវាកម្មអាជីវកម្ម </h4>
                <div class="space-y-2 flex flex-col ">
                    <router-link to="/" class="cursor-pointer hover:text-primery1 font-poppins">Web Design</router-link>
                    <router-link to="/" class="cursor-pointer hover:text-primery1 font-poppins">Bussiness Management System</router-link>
                    <router-link to="/" class="cursor-pointer hover:text-primery1 font-poppins">System Development</router-link>

                    <router-link to="/productsandservices" class="cursor-pointer hover:text-primery1 font-bold">មើលទាំងអស់</router-link>
                </div>
            </div>
            <div class="space-y-3">
                <h4 class="font-bold">ឧបករណ៍</h4>
                <div class="space-y-2 flex flex-col ">
                    <router-link to="/" class="cursor-pointer hover:text-primery1 font-poppins">Label</router-link>
                    <router-link to="/" class="cursor-pointer hover:text-primery1 font-poppins">Printer</router-link>

                    <router-link to="/productsandservices" class="cursor-pointer hover:text-primery1 font-bold">មើលទាំងអស់</router-link>
                </div>

            </div>
        </div>
    </div>

    <!-- mobile  -->

</template>