<template>
  <div
    class="w-full xl:w-full lg:w-[100%]  bg-background flex lg:h-[90px]  duration-300 items-center shadow-sm md:shadow-md justify-center xl:h-[90px] md:h-[80px] h-[80px] sticky top-0 z-[80]">
    <div :class="{
      'duration-300 bg-transparent w-[1280px] flex items-center justify-between mx-auto z-[90]': true
    }">
      <div class="lg:justify-between xl:hidden md:flex flex lg:flex w-full lg:w-[30%]  lg:p-4 items-center z-30">
        <div class="flex items-center justify-center cursor-pointer ml-4 md:ml-8">
          <svg v-motion-fade @click="SideBar" v-if="!isOpenMenuMobile" xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 lg:ml-2 lg:hidden text_navbar" viewBox="0 0 24 24">
            <path fill="#4B5563" d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z" />
          </svg>


          <svg v-motion-fade @click="handleClose" v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
            fill="currentColor" class="w-8 h-8 text_navbar">
            <path
              d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
          </svg>

        </div>
        <div class="flex h-full w-full cursor-pointer gap-2 items-center justify-center lg:ml-1 p-3">
          <img @click="handleGogoHome"
            class="lg:w-[68px] lg:h-[68px] xl:w-[80px] md:w-[55px] md:h-[55px] w-[40px] lg:block"
            src="../assets/image/image 5.png" />
          <h1 class=" font-poppins  md:text-[20px] text-primery1 lg:text-nowrap lg:text-[18px]">
            Master-IT System
          </h1>
        </div>
      </div>
      <div @click="handleGogoHome"
        class="transition-all cursor-pointer duration-300 items-center lg:justify-start xl:justify-start md:justify-center justify-center gap-4 ml-4 lg:hidden xl:flex md:block flex">
        <img class="lg:w-[60px] lg:h-[60px]  md:w-[90px] md:h-[90px] w-[40px] lg:hidden xl:block md:hidden hidden"
          src="../assets/image/image 5.png" alt="" />
        <h1 class="text-primery1 font-poppins  lg:text-[28px] xl:text-[20px] lg:block xl:block md:hidden hidden ">
          Master-IT System
        </h1>
      </div>
      <div
        class="lg:flex xl:flex md:hidden hidden  items-center gap-[72px] lg:gap-[20px] xl:gap-[50px] mr-4 xl:px-3 lg:mr-[60px] xl:mr-0  justify-end font-NotoSansKhmer">


        <router-link class="text_navbar hover:text-primery1  font-bold py-1 relative" to="/">
          <h2 class="lg:text-[18px] xl:text-[20px]">ទំព័រដើម</h2>

          <div v-motion-fade-visible v-if="isActive('/')"
            class="bg_navbar w-full h-[4px]  lg:bottom-1 xl:bottom-0 absolute"></div>
        </router-link>

        <router-link v-if="$route.name === 'bussinessDetail'" class="text_navbar py-1 relative" to="/productsandservices">
          <h2 class="lg:text-nowrap lg:text-[18px] xl:text-[20px]">ផលិតផលនិងសេវាកម្ម</h2>
          <div v-motion-fade-visible class="bg-primery2 w-full h-[4px]  lg:bottom-1 xl:bottom-0 absolute">
          </div>
        </router-link>


        <router-link v-else-if="$route.name === 'bms'" class="text_navbar font-bold py-1 relative" to="/productsandservices">
          <h2 class="lg:text-nowrap lg:text-[18px] xl:text-[20px]">ផលិតផលនិងសេវាកម្ម</h2>
          <div v-motion-fade-visible class="bg-primery2 w-full h-[4px] lg:bottom-1 xl:bottom-0 absolute">
          </div>
        </router-link>


        <router-link v-else-if="$route.name === 'webdesign'" class="text_navbar font-bold py-1 relative" to="/productsandservices">
          <h2 class="lg:text-nowrap lg:text-[18px] xl:text-[20px]">ផលិតផលនិងសេវាកម្ម</h2>
          <div v-motion-fade-visible class="bg-primery2 w-full h-[4px]  lg:bottom-1 xl:bottom-0 absolute">
          </div>
        </router-link>


        <router-link v-else-if="$route.name === 'systemdev'" class="text_navbar  font-bold py-1 relative" to="/productsandservices">
          <h2 class="lg:text-nowrap lg:text-[18px] xl:text-[20px]">ផលិតផលនិងសេវាកម្ម</h2>
          <div v-motion-fade-visible class="bg-primery2 w-full h-[4px] lg:bottom-1 xl:bottom-0 absolute">
          </div>
        </router-link>



        <router-link v-else-if="$route.name === 'systemdetails'" class="text_navbar py-1 relative"
          to="/productsandservices">
          <h2 class="lg:text-nowrap lg:text-[18px] xl:text-[20px]">ផលិតផលនិងសេវាកម្ម</h2>
          <div v-motion-fade-visible class="bg-primery2  h-[4px] rounded-full lg:bottom-1 xl:bottom-0 absolute">
          </div>
        </router-link>




        <!-- <router-link v-else class="text_navbar py-1 relative" to="/productsandservices">
          <h2 class="lg:text-nowrap lg:text-[18px] xl:text-[20px]">ផលិតផលនិងសេវាកម្ម</h2>
          <div v-motion-fade-visible v-if="isActive('/productsandservices')"
            class="bg-primery2 w-full h-[4px] rounded-full lg:bottom-1 xl:bottom-0 absolute"></div>
        </router-link> -->

        <div class="group relative mt-[29px]">
          <div>
            <div class="flex gap-1 items-center">
              <h2
                class="lg:text-nowrap hover:text-primery1  lg:text-[18px] xl:text-[20px] font-bold text-primary1 cursor-pointer group-hover:text-primary-dark text_navbar">
                ផលិតផលនិងសេវាកម្ម
              </h2>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-chevron-down text_navbar">
                <path d="m6 9 6 6 6-6" />
              </svg>

            </div>
            <div class="bg-transparent cursor-pointer h-7  z-[2]">
            </div>
          </div>

          <div
            class="invisible opacity-0  absolute w-[405px] rounded-b-md bg-background shadow-md z-[2] top-[51px] mt-3 transition-all duration-500 ease-out transform translate-y-3 group-hover:visible group-hover:opacity-100 group-hover:translate-y-0">
            <ServiceDropdown />
          </div>
        </div>

        <!-- 
        <router-link class="text_navbar py-1 relative" to="/product">
          <h2 class="lg:text-nowrap lg:text-[18px] xl:text-[20px]">ឧបករណ៍</h2>
          <div v-motion-fade-visible v-if="isActive('/product')"
            class="bg-primery2  w-full h-[4px] rounded-full lg:bottom-1 xl:bottom-0 absolute"></div>
        </router-link> -->

        <router-link class="text_navbar hover:text-primery1  font-bold py-1 relative" to="/aboutUs">
          <h2 class="lg:text-nowrap  lg:text-[18px] xl:text-[20px]">អំពីពួកយើង</h2>
          <div v-motion-fade-visible v-if="isActive('/aboutUs')"
            class="bg_navbar  w-full h-[4px]  lg:bottom-1 xl:bottom-0 absolute"></div>
        </router-link>
        <router-link class="text_navbar hover:text-primery1  font-bold py-1 relative" to="/contact">
          <h2 class="lg:text-nowrap lg:text-[18px] xl:text-[20px]">ទំនាក់ទំនង</h2>
          <div v-motion-fade-visible v-if="isActive('/contact')"
            class="bg_navbar  w-full h-[4px]  lg:bottom-1 xl:bottom-0 absolute"></div>
        </router-link>
      </div>
    </div>

    <Transition>
      <div class="item" v-if="isOpenMenuMobile">
        <!-- <MobileSidebarVue handleCloseMenu="handleCloseMenu" /> -->
        <component :is="currentComponent" @close="handleClose" />
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import MobileSidebarVue from "./MobileSidebar.vue";
import ServiceDropdown from '@/components/ServicedropDownComponent.vue'

export default {
  components: {
    MobileSidebarVue,
    ServiceDropdown
  },
  setup() {
    const nav_item = ref([
      { link: "/", names: "ទំព័រដើម" },
      { link: "/aboutUs", names: "អំពីពួកយើង" },
      { link: "/productsandservices", names: "សេវ៉ាកម្ម" },
      { link: "/contact", names: "ទំនាក់ទំនង" },
    ]);

    const handleCloseMenu = ref(false)

    const isScrolled = ref(false);
    const isOpenMenuMobile = ref(false); // Initially set to false
    const currentComponent = ref("");
    const router = useRouter();

    const SideBar = () => {
      currentComponent.value = "MobileSidebarVue";
      isOpenMenuMobile.value = !isOpenMenuMobile.value;
      toggleScroll(isOpenMenuMobile.value);
    };

    const handleGogoHome = () => {
      router.push({ name: "mainpage" });
    };

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 0;
    };

    const isActive = (path) => {
      return router.currentRoute.value.path === path;
    };

    const handleClose = () => {
      currentComponent.value = "";
      isOpenMenuMobile.value = !isOpenMenuMobile.value;
      toggleScroll(isOpenMenuMobile.value);
    };

    //disable scroll
    const disableScroll = () => {
      document.body.style.overflow = 'hidden';
    };


    const enableScroll = () => {
      document.body.style.overflow = '';
    };

    const toggleScroll = (disable) => {
      if (disable) {
        disableScroll();
      } else {
        enableScroll();
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
      enableScroll();
    });

    return {
      isOpenMenuMobile,
      isScrolled,
      handleGogoHome,
      SideBar,
      currentComponent,
      handleClose,
      nav_item,
      isActive,
      handleCloseMenu
    };
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

/* .router-link-active {
  border-bottom: 3px #500192 solid;
} */
</style>
