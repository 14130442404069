<template>


    <div class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </div>


    <div class="bg-gradient-to-r from-[#500192] to-indigo-400/70 pt-10">
        <div
            class="xl:w-[1280px] xl:h-[600px] lg:h-[450px] md:w-[90%] md:h-[300px] h-[400px]  lg:w-[88%] overflow-hidden mx-auto xl:px-5">
            <div class="md:flex md:justify-between lg:gap- xl:gap-5">
                <div class="md:mt-5 lg:mt-20 xl:mt-28 mt-14">
                    <div
                        class="font-NotoSansKhmer text-center md:text-start xl:p-5 space-y-2.5 px-5 md:p-0 md:space-y-1.5">
                        <!-- Fade-up for Title -->
                        <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                            <p v-if="showTitle"
                                class="text-white  text-[20px] md:text-[20px]  xl:text-[20px] lg:text-[20px] font-poppins font-[600] animate-title">
                                Master-IT System
                            </p>
                        </transition>

                        <!-- Fade-up for Khmer Title -->
                        <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                            <h2 v-if="showKhmerTitle"
                                class="lg:text-[30px] text-[30px]  xl:text-[45px] md:text-[30px] font-bold text-background animate-title">
                                ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម
                            </h2>
                        </transition>

                        <!-- Fade-up for Subheading -->
                        <div class="space-y-5 md:space-y-0">
                            <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                                <h2 v-if="showSubheading"
                                    class="font-[500] text-[20px] text-background lg:text-[15px] md:text-[15px] xl:text-[22px] animate-title">
                                    ពួកយើងជួយអ្នកឲគ្រប់គ្រង់អាជីវកម្ម​ របស់អ្នកងាយស្រួល។
                                </h2>
                            </transition>

                            <!-- Fade-up for Button -->
                            <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                                <div v-show="showSubheading">
                                    <!-- <button
                                        class="px-[24px] z-50 text-[17px] md:mt-[25px] md:text-[14px] lg:text-[16px] xl:text-[18px] py-[12px] font-NotoSansKhmer font-semibold  text-white bg-gradient-to-r from-purple-600 to-indigo-700 shadow-xl hover:opacity-80 duration-300 transform hover:scale-105">
                                        ទាក់ទងមកពួកយើង
                                    </button> -->
                                    <button
                                        class="btn-hover text-[17px] md:mt-[25px] md:text-[14px] lg:text-[16px] xl:text-[18px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10 text-white text-2xl py-2 px-4 mt-4 hover:border-[1px] hover:border-blue-500 overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-700 shadow-xl">
                                        ទាក់ទងមកពួកយើង
                                    </button>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                

                <transition name="fade-up" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                    <div v-if="showTitle" class="hidden md:block">
                        <img class="md:w-[400px] md:h-[300px] lg:w-[600px] lg:h-[500px] xl:w-[700px] xl:h-[600px] object-contain"
                            src="../../src/assets/pngwing.com.png" alt="">

                            <!-- <img class="md:w-[400px] md:h-[300px] lg:w-[600px] lg:h-[500px] xl:w-[700px] xl:h-[600px] object-contain"
                            src="https://fakeimg.pl/400x300/" alt=""> -->

                    </div>
                </transition>


            </div>
        </div>
    </div>




    <!-- <button
        class="btn-hover grow_skew_backward relative z-10 text-white text-2xl bg-blue-500 py-3 px-4 m-4 overflow-hidden">
        Button with Skew Backward
    </button> -->


</template>

<script>
import { ref } from "vue";
export default {
    setup() {
        const showTitle = ref(false);
        const showKhmerTitle = ref(false);
        const showSubheading = ref(false);
        const showButton = ref(false);

        setTimeout(() => showTitle.value = true, 300);
        setTimeout(() => showKhmerTitle.value = true, 900);
        setTimeout(() => showSubheading.value = true, 1300);
        setTimeout(() => showButton.value = true, 2000);

        const beforeEnter = (el) => {
            el.style.opacity = 0;
            el.style.transform = "translateY(90px)";
        };
        const enter = (el, done) => {
            el.offsetHeight; // trigger reflow
            el.style.transition = "opacity 1s ease-out, transform 1s ease-out";
            el.style.opacity = 1;
            el.style.transform = "translateY(0px)";
            done();
        };
        const leave = (el, done) => {
            el.style.transition = "opacity 0.1s ease-in,  0.2s ease-in";
            el.style.opacity = 0;
            el.style.transform = "translateY(-30px)"; // Move up when leaving
            done();
        };

        return {
            showTitle,
            showKhmerTitle,
            showSubheading,
            showButton,
            beforeEnter,
            enter,
            leave,
        };
    }
};
</script>

<style scoped>
/* Fade-up transition styles */
.fade-up-enter-active,
.fade-up-leave-active {
    transition: opacity 1s ease-out, transform 0.1s ease-out;
}

.fade-up-enter,
.fade-up-leave-to {
    opacity: 0;
    transform: translateY(30px);
    /* Move the element up */
}

/* Optional button hover effect */




.context {
    width: 100%;
    position: absolute;
    top: 50vh;

}

.context h1 {
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.area {
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
</style>