<template>
  <div class="flex flex-col">
    <div
     v-motion :initial="{
      opacity: 0.1,
    }" :enter="{

      opacity: 0.8,
      transition: {
        duration: 500,
        ease: 'easeIn',
      },
    }"
    
     class="w-full max-w-full h-auto lg:mt-10 xl:mt-[12px]  flex flex-col items-center justify-center">
      <div class="xl:-mt-4 md:mt-0 lg:mt-0 -mt-5  text-center">
        <h2
          class="hidden font-NotoSansKhmer  font-bold text-primery1 text-heading4 lg:text-Heading1 xl:text-Heading1 md:text-heading2">
          អំពីយើង
        </h2>
      </div>
      <div
        class=" hidden md:hidden lg:hidden w-full max-w-[1280px] xl:px-2 space-y-4 lg:p-0 xl:p-0 p-3 md:p-3 mx-auto border-b-gray-400 lg:h-auto xl:h-auto md:h-auto h-auto  xl:flex xl:flex-row  md:flex-col-reverse  flex-col-reverse xl:mt-8 lg:mt-8 md:mt-0 mt-0">
        <div class="lg:w-full xl:w-1/2 w-full xl:mt-4 lg:mt-4 md:mt-4 mt-4">
          <img src="../assets/webiste/about.png"
            class="md:w-full md:h-full lg:w-[660px] lg:h-[400px] xl:w-[95%] w-[95%] h-full rounded-md mx-auto" alt="" />
        </div>
        <div class="lg:w-full flex  xl:w-1/2 w-[95%] lg:mt-0 xl:mt-0 h-full mx-auto ">
          <div class="xl:space-x-[30px]">

            <div class="relative xl:ml-[30px]">
              <h2
                class=" xl:text-[35px] xl:text-start font-NotoSansKhmer font-bold text-primery1 text-heading4 lg:text-Heading1 xl:border-l-primery1 rounded-r-md  xl:pl-[35px] md:text-heading2">
                អំពីយើង
              </h2>
              <div class="absolute top-0 rounded-full h-[50px] w-1.5 bg-primery1">

              </div>
            </div>
            <p style="line-height: 1.7" class="text-[16px] xl:text-[20px] xl:mt-[30px] space-y-2">
              ម៉ាស្ទឺអាយធី សុីស្ទឹម (Master-IT System) គឺជាក្រុនហ៊ុនផ្តល់ដំណោះស្រាយផ្នែកបច្ចេកវិទ្យា
              ដែលមានរ​ឯកទេសក្នុងការអភិវឌ្ឍន៍ប្រព័ន្ធ (Software Development)
              ការចនាកែហទំព័រ (Web Design) និងការប្រឹក្សាយោបល់ជួយម្ចាស់អាជីវកម្ម
              ឲទទួលបានប្រព័ពន្ធគ្រប់គ្រងដែលឆ្លើយតបនឹងតម្រូវការបំផុត ហើយចំណាយប្រាក់តិចបំផុត
              (Bussiness Software Consultant) ។


            </p>
          </div>
        </div>
      </div>









      <div class=" xl:hidden lg:grid lg:grid-cols-2 md:grid-cols-1 lg:pl-10 ">
        <h2
          class=" text-[24px]  md:text-[28px]  lg:ml-[24px] lg:hidden text-center mt-10 mb-7 md:my-10 md:p-1 lg:text-[30px] xl:text-[35px] xl:text-start font-NotoSansKhmer mb font-bold text-primery1  xl:border-l-primery1 rounded-r-md ">
          អំពីយើង
        </h2>
        <div class="lg:w-[90%] lg:mx-auto mx-10  md:-mt-0">
          <!-- <img src="../assets/webiste/about.png"
            class="lg:w-[526px]  lg:object-cover lg:h-[347px] rounded-[6px] xl:mx-auto w-full" alt="" /> -->
            <img src="../assets/webiste/about.png" class="lg:w-[526px]  lg:object-cover lg:h-[347px] rounded-[6px] xl:mx-auto w-full" alt="">
        </div>
        <div style=" line-height: 2"
          class="text-[14px] p-5 lg:p-0 md:text-[18px]  lg:w-full  lg:block   lg:text-justify lg:pr-10 lg:text-[16px]  lg:mt-[10px]  xl:mt-[30px] space-y-2">
          <!-- តម្រូវការអនាគតឆ្ពោះទៅរកបច្ចេកវិទ្យាឌីជីថលទំនើប និង
              មើលឃើញពីការលំបាករបស់អាជីវកម្មជាច្រើននៅក្នុងការគ្រប់គ្រងប្រតិបត្តការប្រចាំថ្ងៃ
              ដែលមានតែភាពមិនច្បាស់លាស់ មិន រលូន មានតែការបាត់បង់
              និងមានភាពខ្ជះខ្ជាយ ទាំងនេះជាឧបសគ្គដ៏ធំ រារាំងអាជីវកម្មជាច្រើន
              មិនអាចរីកចម្រើនបានលឿន ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម
              ត្រូវបានបង្កើតឡើងដើម្បីឆ្លើយតបលើតម្រូវការ និងដោះស្រាយរាល់បញ្ហា
              ការលំបាកទាំងអស់នេះរបស់ អាជីវកម្ម
              ហើយជួយឱ្យអាជីវកម្មអាចគ្រប់គ្រងបានកាន់តែស្រួល កាន់តែច្បាស់
              និងរីកចម្រើនកាន់តែលឿន តាមរយៈការបន្ត អភិវឌ្ឍន៍ឥតឈប់ឈរ
              នូវប្រព័ន្ធបច្ចេកវិទ្យាដែលទំនើប ទាន់សម័យ ស្តង់ដារ សាមញ្ញ
              ងាយស្រួលប្រើ។ -->

          <div class="relative hidden md:hidden  lg:block">
            <h2
              class="lg:ml-[24px] lg:text-[30px] xl:text-[35px] xl:text-start font-NotoSansKhmer font-bold text-primery1 text-heading4  xl:border-l-primery1 rounded-r-md   md:text-heading2">
              អំពីយើង
            </h2>
            <div class="absolute top-2 rounded-full h-[45px] w-1.5 bg-primery1">

            </div>
          </div>
          <p>
            <b>ម៉ាស្ទ័រអាយធី ស៊ីស្ទឹម (Master-IT System)</b> គឺជាក្រុនហ៊ុនផ្តល់ដំណោះស្រាយផ្នែកបច្ចេកវិទ្យា
            ដែលមានរ​ឯកទេសក្នុងការអភិវឌ្ឍន៍ប្រព័ន្ធ (Software Development)
            ការចនាកែហទំព័រ (Web Design) និងការប្រឹក្សាយោបល់ជួយម្ចាស់អាជីវកម្ម
            ឲទទួលបានប្រព័ពន្ធគ្រប់គ្រងដែលឆ្លើយតបនឹងតម្រូវការបំផុត ហើយចំណាយប្រាក់តិចបំផុត
            (Bussiness Software Consultant) ។
          </p>

        </div>
        <div class="lg:mx-auto flex  xl:w-1/2 w-[95%] lg:mt-0 xl:mt-0 h-full mx-auto ">

          <div class="xl:space-x-[30px]">

            <p style=" line-height: 2"
              class="text-[14px] p-5 lg:p-0 md:text-[18px]  lg:w-full  lg:block   lg:text-justify lg:pr-10 lg:text-[16px]  lg:mt-[10px]  xl:mt-[30px] space-y-2">
            
            </p>
          </div>
        </div>
      </div>


      <div style="background-color: rgba(80, 1, 146, 0.02);" class="lg:mt-10 w-full  xl:w-full -mt-5   md:-mt-0 ">
        <div
          class="xl:max-w-[1280px] lg:-mt-5  mx-auto grid lg:grid-cols-2  md:grid-cols-1 xl:gap-[10px] w-full xl:mt-10 gap-y-4 py-6 lg:px-10 xl:px-3 lg:py-0 xl:py-0 ">



          <div class="xl:w-[100%] lg:w-[90%] md:w-[90%] w-[90%]  xl:h-[260px] h-auto  rounded-[6px] mx-auto">
            <div class="lg:w-auto lg:h-[46px] xl:w-auto xl:h-[46px] w-auto h-auto  rounded-[6px] mb-2">
              <p
                class="text-center md:my-5 my-3  xl:my-0 lg:hidden xl:block xl:text-[28px]  lg:text-heading2 font-bold  text-[20px] text-primery1 font-NotoSansKhmer">
                ទស្សនៈវិស័យ
              </p>
            </div>
            <div class="xl:w-[90%]  flex items-center ">
              <p
                class="text-[16px] md:text-[20px]  xl:text-[20px] text-black font-[400] space-y-5 lg:hidden xl:flex  font-NotoSansKhmer text-start xl:p-2">
                យើងជឿជាក់ថា កត្តាដ៏សំខាន់មួយជួយធ្វើឱ្យអាជីវកម្ម និងប្រទេសជាតិរីកចម្រើនលឿន គឺកត្តាជឿនលឿននៃបច្ចេកវិទ្យា
                ម៉ាស្ទ័រអាយធីស៊ីស្ទឹម នឹងក្លាយជាក្រុមហ៊ុនបច្ចេកវិទ្យា ឈានមុខគេបង្កើតមោតទនភាពជូនប្រជាជនកម្ពុជា។
              </p>

              <div class="hidden lg:flex xl:hidden space-x-[10px]">
                <div class="lg:text-[24px] font-bold flex flex-nowrap text-primery1 space-x-5">
                  <div class="lg:text-[24px] font-bold flex text-primery1 space-x-5 text-nowrap">
                    <div class="w-2 h-2 mt-3 mr-3 rounded-full  bg-primery1 "></div>
                    ទស្សនៈវិស័យ :
                  </div>

                </div>
                <p
                  class="text-[18px] xl:text-[20px] lg:space-y-[10px] lg:mt-[7px] font-NotoSansKhmer   text-start xl:p-2">
                  យើងជឿជាក់ថាកត្តាដ៏សំខាន់មួយជួយ
                </p>
              </div>
            </div>
            <div
              class="text-[18px]  hidden lg:block xl:hidden pl-5 mt-1 xl:text-[20px]  font-NotoSansKhmer space-y-2 xl:p-2">
              <p>ធ្វើឱ្យអាជីវកម្មនិងប្រទេសជាតិរីកចម្រើនលឿន គឺកត្តាជឿនលឿន</p>
              <p>នៃបច្ចេកវិទ្យាម៉ាស្ទ័រអាយធីស៊ីស្ទឹមនឹងក្លាយជាក្រុមហ៊ុនបច្ចេក</p>
              <p>វិទ្យាឈានមុខគេបង្កើតមោតទនភាពជូនប្រជាជនកម្ពុជា</p>

            </div>
          </div>






          <div class="w-[90%]   xl:h-[260px] h-auto  rounded-[6px] mx-auto">
            <div class="lg:w-auto lg:h-[46px] xl:w-auto xl:h-[46px] w-auto h-auto px-2 rounded-[6px] mb-2">
              <p
                class="text-center md:my-5  xl:my-0 lg:hidden xl:block xl:text-[28px]  lg:text-heading2 font-bold  text-[20px] text-primery1 font-NotoSansKhmer">
                បេសកម្ម
              </p>
            </div>
            <div class="w-[100%]  flex items-center ">
              <p
                class="text-[16px] md:text-[20px] xl:text-[20px] text-black font-[400] space-y-5 lg:hidden xl:flex  font-NotoSansKhmer text-start xl:p-2">
                នាំមកនូវប្រព័ន្ធបច្ចេកវិទ្យាថ្មី ឈានមុខគេសម្រាប់គ្រប់អាជីវកម្ម
                និងប្រទេសកម្ពុជា តាមរយៈការរួមគ្នាបន្តការស្រាវជ្រាវ
                បន្តការអភិវឌ្ឍន៍ឥតឈប់ឈរ បន្តការផ្ដល់ជូននូវបទពិសោធន៍ប្រើប្រាស់
                និងសេវាកម្មដ៏ល្អជាទីទុកចិត្ត ពេញចិត្តជូនអតិថិជនគ្រប់កាលៈទេសៈ។
              </p>

              <div class="hidden lg:flex xl:hidden space-x-[10px]">
                <div class="lg:text-[24px] font-bold flex flex-nowrap text-primery1 space-x-5">
                  <div class="lg:text-[24px] font-bold flex text-primery1 space-x-5 text-nowrap">
                    <div class="w-2 h-2 mt-3 mr-3 rounded-full  bg-primery1 "></div>
                    បេសកម្ម :
                  </div>

                </div>
                <p
                  class="text-[18px] xl:text-[20px] lg:space-y-[10px] lg:mt-[7px] font-NotoSansKhmer   text-start xl:p-2">
                  នាំមកនូវប្រព័ន្ធបច្ចេកវិទ្យាថ្មីឈានមុខគេ
                </p>
              </div>
            </div>
            <div
              class="text-[18px]  hidden lg:block xl:hidden pl-5 mt-1 xl:text-[20px]  font-NotoSansKhmer space-y-2 xl:p-2">
              <p>សម្រាប់គ្រប់គ្រងអាជីវកម្មនិងប្រទេសកម្ពុជា តាមរយៈការរួមគ្នារ</p>
              <p>បន្តកាស្រាវជ្រាវបន្តការអភិវឌ្ឍន៍ឥតឈប់ឈរ បន្តការផ្តល់ជូន</p>
              <p>នូវបទពិសោធន៍ប្រើប្រាស់និងសេវាកម្មដ៏ល្អជាទីទុកចិត្តពេញ</p>
              <p>ចិត្តជូនអតិថិជនគ្រប់កាលៈទេសៈ។</p>
            </div>
          </div>



          <div class="w-[90%] xl:w-full xl:h-[260px] h-auto  rounded-[6px] mx-auto  xl:mb-7">
            <div class="lg:w-auto lg:h-[46px] xl:w-auto xl:h-[46px] w-auto h-auto px-2 rounded-[6px]">
              <p
                class="text-center md:my-5 mb-2  xl:my-0 lg:hidden xl:block xl:text-[28px]  lg:text-heading2 font-bold  text-[20px] text-primery1 font-NotoSansKhmer">
                គុណតម្លៃ
              </p>
            </div>
            <div class="w-[100%] mx-auto flex items-center justify-start x">
              <div
                class="text-[16px]  xl:text-[20px] text-black font-[400] space-y-5 lg:hidden xl:flex  font-NotoSansKhmer text-start xl:p-2">
                <div class="space-y-1 text-[16px] md:text-[20px] xl:text-[20px]">

                  <ul class="list-outside flex gap-[10px] ">
                    <div>
                      <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                    </div>
                    <li> យើងផ្តល់នូវផលិតផលនិងសេវាកម្ម ដែលមានគុណភាព ត្រឹមត្រូវ ច្បាស់លាស់។</li>
                  </ul>
                  <ul class="list-outside flex gap-[10px]  ">
                    <div>
                      <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                    </div>
                    <li>ប្រព័ន្ធស្ដង់ដារ (ទំនើប ច្បាស់លាស់ សាមញ្ញ ងាយស្រួលប្រើ) និងខុសប្លែកពីគេ។</li>
                  </ul>

                  <ul class="list-outside flex gap-[10px] ">
                    <div>
                      <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                    </div>
                    <li>សេវាកម្មលឿនរហ័សបំផុត ជាទីពេញចិត្ត និងទំនុកចិត្ត មានវិជ្ជាជីវៈ ជាទីពេញចិត្តគ្រប់កាល:ទេស:។</li>
                  </ul>

                  <ul class="list-outside flex gap-[10px] ">
                    <div>
                      <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                    </div>
                    <li>យើងមានសីលធម៌ សុជីវធម៌ ស្មោះត្រង់ ទទួលខុសត្រូវ​ និងការស្មោះត្រង់។</li>
                  </ul>

                  <ul class="list-outside flex gap-[10px] ">
                    <div>
                      <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                    </div>
                    <li>យើងសហការគ្នា សាមគ្គីគ្នា ដើម្បីភាពរីកចម្រើនរួមគ្នា។</li>
                  </ul>




                  <!-- <li>
                    ប្រព័ន្ធស្ដង់ដារ (ទំនើប ច្បាស់លាស់ សាមញ្ញ ងាយស្រួលប្រើ)
                  </li>
                  <li>
                    សេវាកម្មលឿនរហ័សបំផុត ជាទីពេញចិត្ត និងទំនុកចិត្ត មានវិជ្ជាជីវៈ
                  </li>

                  <li>
                    ស្មោះត្រង់ ទទួលខុសត្រូវ ភាពជាដៃគូរដ៏ល្អ និងយូរអង្វែង
                  </li>
                  <li>
                    ភាពជាដៃគូដ៏ល្អយូអង្វែង។
                  </li> -->

                </div>

              </div>

              <div class="hidden lg:block xl:hidden space-x-[10px] -mt-9">
                <div class="lg:text-[24px] mb-2 font-bold flex text-primery1 items-center space-x-5">
                  <div class="w-2 h-2 mt-10 mr-3 rounded-full bg-primery1 "></div><br> គុណតម្លៃ :
                </div>
                <div class="text-[18px] xl:text-[20px]  lg:space-y-[10px] pl-3  font-NotoSansKhmer   text-start xl:p-2">
                  <div class="space-y-1">
                    <li> យើងផ្តល់នូវផលិតផលនិងសេវាកម្ម ដែលមានគុណភាព ត្រឹមត្រូវ ច្បាស់លាស់។</li>
                    <li>ប្រព័ន្ធស្ដង់ដារ (ទំនើប ច្បាស់លាស់ សាមញ្ញ ងាយស្រួលប្រើ) និងខុសប្លែកពីគេ។</li>
                    <li>សេវាកម្មលឿនរហ័សបំផុត ជាទីពេញចិត្ត និងទំនុកចិត្ត មានវិជ្ជាជីវៈ ជាទីពេញចិត្តគ្រប់កាល:ទេស:។</li>

                    <li>យើងមានសីលធម៌ សុជីវធម៌ ស្មោះត្រង់ ទទួលខុសត្រូវ​ និងការស្មោះត្រង់។</li>
                    <li>យើងសហការគ្នា សាមគ្គីគ្នា ដើម្បីភាពរីកចម្រើនរួមគ្នា។</li>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="w-[90%]  xl:h-[260px] h-auto  rounded-[6px] mx-auto">
            <div class="lg:w-auto lg:h-[46px] xl:w-auto xl:h-[46px] w-auto h-auto px-2 rounded-[6px] mb-2">
              <p
                class="text-center md:my-5  xl:my-0 lg:hidden xl:block xl:text-[28px]  lg:text-heading2 font-bold  text-[20px] text-primery1 font-NotoSansKhmer">
                យើងផ្តោតលើ
              </p>
            </div>
            <div class="w-[100%]  flex items-center ">
              <div
                class="text-[16px] xl:text-[20px] md:text-[20px] text-black font-[400] xl:-mt-2 space-y-1 lg:hidden xl:block  font-NotoSansKhmer text-start xl:p-2">
                <p>1. ឆ្នៃប្រឌិត បង្កើតថ្មី ចាប់​ឱកាស មុនគេ។</p>
                <p>2. បំពេញតម្រូវការ រក្សាភាពពេញចិត្តដល់អតិថិជន។</p>
                <p>3. ជំរុញទីផ្សារ បង្កើនការលក់។</p>

              </div>

              <div class="hidden lg:flex xl:hidden space-x-[10px]">
                <div class="lg:text-[24px] font-bold flex flex-nowrap text-primery1 space-x-5">
                  <div class="lg:text-[24px] font-bold flex text-primery1 space-x-5 text-nowrap">
                    <div class="w-2 h-2 mt-3 mr-3 rounded-full  bg-primery1 "></div>
                    យើងផ្តោតលើ :
                  </div>

                </div>
                <p
                  class="text-[18px] xl:text-[20px] lg:space-y-[10px] lg:mt-[7px] font-NotoSansKhmer   text-start xl:p-2">
                  <!-- ការស្រាវជ្រាវនិងអភិវឌ្ឍន៍ឥតឈប់ឈរ -->
                </p>
              </div>
            </div>
            <div
              class="text-[18px]  hidden lg:block xl:hidden pl-5 mt-1  xl:text-[20px]  font-NotoSansKhmer space-y-2 xl:p-2">
            
              <p>1. ឆ្នៃប្រឌិត បង្កើតថ្មី ចាប់​ឱកាស មុនគេ។</p>
              <p>2. បំពេញតម្រូវការ រក្សាភាពពេញចិត្តដល់អតិថិជន។</p>
              <p>3. ជំរុញទីផ្សារ បង្កើនការលក់។</p>
            </div>
          </div>




        </div>

        <div class="p-6 md:mt-5  bg-[#500192]/50 lg:mt-10 xl:mt-0">
        </div>
      </div>


      <!-- //below -->
      <div class="xl:mb-8">
        <div
          class="xl:w-[1280px] xl:px-10 mx-auto grid lg:grid-cols-2 lg:gap-10  lg:mt-10 md:grid-cols-1 w-full xl:gap-[5rem] gap-y-4 py-6 lg:px-10  lg:py-0 xl:py-0 mb-10 md:mb-[50px] lg:mb-[40px] xl:mb-[10rem] ">
          <div class="  w-[90%]   xl:w-full md:w-[90%] xl:h-[260px] h-auto  rounded-[6px] mx-auto">
            <div
              class="lg:w-auto flex justify-center lg:h-[46px] xl:w-auto xl:h-[46px] w-auto h-auto px-2 rounded-[6px] mb-2">
              <div class="">
                <p
                  class="text-[20px] text-center md:text-[26px] xl:text-[28px] md:my-5  xl:my-0  xl:block  lg:text-heading2 font-bold  font-NotoSansKhmer">
                  ហេតុអ្វីជ្រើសរើស ម៉ាស្ទឺអាយធី សុីស្តឹម?
                </p>
                <div
                  class="w-[220px] xl:w-[460px] ml-10 md:mr-10  md:w-[460px] lg:mr-12 lg:w-[360px] h-[3px] rounded-full mt-[10px]  bg-primery1">
                </div>
              </div>
            </div>
            <div class="w-[100%] mt-[16px] mx-auto flex  items-center lg:mt-10 xl:mt-4 justify-center">
              <div
                class="text-[16px] md:block hidden  md:text-[20px] lg:text-[18px] xl:text-[20px] text-black font-[400] space-y-[10px] lg:p-5 font-NotoSansKhmer  text-start xl:p-0 xl:ml-4">

                <ul class="list-outside flex gap-[10px] md:mt-4 lg:mt-0 xl:mt-4 ">
                  <div>
                    1.
                  </div>
                  <p> មានបទពិសោធន៍ក្នុងវិស័យបច្ចេកវិទ្យា និងអាជីវកម្មចាប់តាំងពីឆ្នាំ២០១២</p>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    2.
                  </div>
                  <p>នាំមកនូវប្រព័ន្ធបច្ចេកវិទ្យា មានគុណភាព និងមានស្តង់ដារ</p>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    3.
                  </div>
                  <p class="xl:text-nowrap">ផ្ដល់ទំនុកចិត្តកំរិតខ្ពស់បំផុត ដោយមានការធានា៥​យ៉ាង</p>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    4.
                  </div>
                  <p class="lg:text-nowrap">ផ្តល់ក្រុមការងារជំនាញ ជួយគាំទ្រអតិថិជនបានគ្រប់កាល:ទេស:</p>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    5.
                  </div>
                  <p>ផ្តល់ជូនការប្រឹក្សាដោយឥតគិតថ្លៃ
                    ជួយឲម្ចាស់អាជីវកម្មបានប្រព័ន្ធដែលឆ្លើយតបតម្រូវការបំផុតហើយចំណាយប្រាក់តិចបំផុត។</p>
                </ul>

              


              </div>

              <div
                class="text-[16px] p-2 md:hidden  md:text-[20px] lg:text-[18px] xl:text-[20px] space-y-[10px] lg:p-5 font-NotoSansKhmer  text-start xl:p-3">
                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                  </div>
                  <li>មានបទពិសោធន៍ក្នុងវិស័យបច្ចេកវិទ្យា និងអាជីវកម្មចាប់តាំងពីឆ្នាំ២០១២</li>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                  </div>
                  <li>នាំមកនូវប្រព័ន្ធបច្ចេកវិទ្យា មានគុណភាព និងមានស្តង់ដារ</li>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                  </div>
                  <li>ផ្ដល់ទំនុកចិត្តកំរិតខ្ពស់បំផុត ដោយមានការធានា៥​យ៉ាង</li>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                  </div>
                  <li>ផ្តល់ក្រុមការងារជំនាញ ជួយគាំទ្រអតិថិជនបានគ្រប់កាល:ទេស:</li>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                  </div>
                  <li>ផ្តល់ជូនការប្រឹក្សាដោយឥតគិតថ្លៃ
                    ជួយឲម្ចាស់អាជីវកម្មបានប្រព័ន្ធដែលឆ្លើយតបតម្រូវការបំផុតហើយចំណាយប្រាក់តិចបំផុត។</li>
                </ul>

              


              </div>

            </div>
          </div>


          <div class="xl:ml-[3rem]  w-[90%] md:w-[90%] xl:h-[260px] h-auto  rounded-[6px] mx-auto md:mt-5 lg:mt-0">
            <div
              class="lg:w-auto flex justify-center lg:h-[46px] xl:w-auto xl:h-[46px] w-auto h-auto px-2 rounded-[6px] mb-2">
              <div class="">
                <p
                  class="text-[20px] text-center md:text-[26px] xl:text-[28px] md:my-5  xl:my-0  xl:block  lg:text-heading2 font-bold  font-NotoSansKhmer">
                  ការធានា
                </p>
                <div
                  class="w-[220px] xl:w-[460px] ml-10 mr-10 md:w-[460px] lg:mr-12 lg:w-[330px] h-[3px] rounded-full mt-[10px]  bg-primery1">
                </div>
              </div>
            </div>
            <div class="w-[100%] mt-[16px] mx-auto flex  xl:pl-6  items-center lg:mt-10 xl:mt-4 justify-center">
              <div
                class="text-[16px] md:block hidden  md:text-[20px] lg:text-[18px] xl:text-[20px] text-black font-[400] space-y-[10px] lg:p-5 font-NotoSansKhmer  text-start xl:p-3">

                <ul class="list-outside flex gap-[10px] md:mt-4 lg:mt-0 ">
                  <div>
                    1.
                  </div>
                  <p> ធានាលើការបង្កើត និងគ្រប់គ្រងរាល់បញ្ហា Error ទាំងអស់នៅក្នុងប្រព័ន្ធគ្រប់គ្រង ទិន្នន័យបើមាន។</p>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    2.
                  </div>
                  <p class="lg:text-nowrap"> ធានាលើការបាត់បង់ទិន្នន័យ ក្នុងទំនួលខុសត្រូវរបស់ក្រុមហ៊ុន។</p>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    3.
                  </div>
                  <p class="lg:text-nowrap">ធានារក្សាការសម្ងាត់បំផុតនៃទិន្នន័យទាំងអស់ក្នុងប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ។</p>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    4.
                  </div>
                  <p class=" lg:text-wrap">ធានាលើការចម្លងទុក(Backup)ទិន្នន័យទាំងអស់ក្នុងប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ។</p>
                </ul>

                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    5.
                  </div>
                  <li>ធានាសង់ប្រាក់ជូនវិញ100% បើយើងខ្ញុំខកខានមិនបានជួយដោះស្រាយបញ្ហារបស់អតិថិជនលើសពី២៤ម៉ោង។</li>
                </ul>




              </div>

              <!-- <div
                class="text-[16px]  p-2 md:hidden  md:text-[20px] lg:text-[18px] xl:text-[20px] space-y-[10px] lg:p-5 font-NotoSansKhmer  text-start xl:p-3">
                <li>ធានាលើការបង្កើត និងគ្រប់គ្រងរាល់បញ្ហា Error ទាំងអស់នៅក្នុងប្រព័ន្ធគ្រប់គ្រង ទិន្នន័យបើមាន។</li>
                <li>ធានាលើការបាត់បង់ទិន្នន័យ ក្នុងទំនួលខុសត្រូវរបស់ក្រុមហ៊ុន។</li>
                <li>ធានារក្សាការសម្ងាត់បំផុតនៃទិន្នន័យទាំងអស់ក្នុងប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ។</li>
                <li>ធានាលើការចម្លងទុក (Backup) ទិន្នន័យទាំងអស់ក្នុងប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ។</li>
                <li>ធានាបើអតិថិជនមិនពេញចិត្ត មិនគិតប្រាក់។</li>

              </div> -->

              <div
                class="text-[16px]  p-2 md:hidden  md:text-[20px] lg:text-[18px] xl:text-[20px] space-y-[10px] lg:p-5 font-NotoSansKhmer  text-start xl:p-3">
                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                  </div>
                  <li>ធានាលើការបង្កើត និងគ្រប់គ្រងរាល់បញ្ហា Error ទាំងអស់នៅក្នុងប្រព័ន្ធគ្រប់គ្រង ទិន្នន័យបើមាន។</li>
                </ul>
                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                  </div>
                  <li>ធានាលើការបាត់បង់ទិន្នន័យ ក្នុងទំនួលខុសត្រូវរបស់ក្រុមហ៊ុន។</li>
                </ul>
                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                  </div>
                  <li>ធានារក្សាការសម្ងាត់បំផុតនៃទិន្នន័យទាំងអស់ក្នុងប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ។</li>
                </ul>
                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                  </div>
                  <li>ធានាលើការចម្លងទុក (Backup) ទិន្នន័យទាំងអស់ក្នុងប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ។</li>
                </ul>
                <ul class="list-outside flex gap-[10px] ">
                  <div>
                    <div class="h-1.5 w-1.5 bg-black rounded-full mt-2.5"></div>
                  </div>
                  <li>ធានាសង់ប្រាក់ជូនវិញ100% បើយើងខ្ញុំខកខានមិនបានជួយដោះស្រាយបញ្ហារបស់អតិថិជនលើសពី២៤ម៉ោង។</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
