<template>
    <div class="xl:w-[1280px] mx-auto my-10 px-5">
        <div>
            <div class="flex justify-start">
                <!-- <h1
                    class="text-center text-primery1 text-[20px] lg:text-[24px] xl:text-[28px] md:text-[28px] border-b-[3px] border-primery1 inline-block pb-1">
                    ហេតុអ្វីជ្រើសរើស ម៉ាស្ទ័រអាយធី សុីស្តឹម?
                </h1> -->

                <h1
                    class="text-center text-primery1 text-[20px] lg:text-[24px] xl:text-[28px] md:text-[28px]  inline-block">
                    ហេតុអ្វីជ្រើសរើស ម៉ាស្ទ័រអាយធី សុីស្តឹម?
                </h1>
            </div>
        </div>

        <div class="space-y-2">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 xl:grid-cols-5 gap-5 mt-5">
             
                <div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;"
                    class="relative bg-white overflow-hidden transition-transform duration-300 hover:scale-105  cursor-pointer rounded-md">
                    <div class="absolute inset-0 border-2 border-transparent hover:border-blue-500 
                                transition-all duration-500 animate-border-animation rounded-md"></div>

                    <div class="flex justify-center p-5">
                        <div
                            class="bg-gradient-to-r from-[#500192]/90 to-indigo-600 shadow-lg p-2 w-16 h-16 flex items-center justify-center rounded-full">
                            <img src="../../src/assets/icons8-experience-100.png" class="w-[40px]"
                                alt="Experience Icon">
                        </div>
                    </div>
                    <div class="px-4 pb-6">
                        <p class="text-center font-bold text-black/70 text-lg">មានបទពិសោធន៍ក្នុងវិស័យបច្ចេកវិទ្យា
                            និងអាជីវកម្មចាប់តាំងពីឆ្នាំ២០១២។</p>
                    </div>
                </div>

                <div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;"
                    class="relative bg-white overflow-hidden transition-transform duration-300 hover:scale-105  cursor-pointer rounded-md">
                    <div class="absolute inset-0 border-2 border-transparent hover:border-blue-500 
                                transition-all duration-500 animate-border-animation rounded-md"></div>

                    <div class="flex justify-center p-5">
                        <div
                            class="bg-gradient-to-r from-[#500192]/90 to-indigo-600 shadow-md p-2 w-16 h-16 flex items-center justify-center rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="lucide lucide-thumbs-up text-white">
                                <path d="M7 10v12" />
                                <path
                                    d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2a3.13 3.13 0 0 1 3 3.88Z" />
                            </svg>
                        </div>
                    </div>
                    <div class="px-4 pb-6">
                        <p class="text-center font-bold text-black/70 text-lg">នាំមកនូវប្រព័ន្ធបច្ចេកវិទ្យា មានគុណភាព
                            និងមានស្តង់ដារ។</p>
                    </div>
                </div>


                <div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;"
                    class="relative bg-white overflow-hidden transition-transform duration-300 hover:scale-105  cursor-pointer rounded-md">
                    <div class="absolute inset-0 border-2 border-transparent hover:border-blue-500 
                                transition-all duration-500 animate-border-animation rounded-md"></div>
                    <div class="flex justify-center p-5">
                        <div
                            class="bg-gradient-to-r from-[#500192]/90 to-indigo-600 shadow-md p-2 w-16 h-16 flex items-center justify-center rounded-full text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-[30px]">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                            </svg>

                        </div>
                    </div>
                    <div class="px-4 pb-6">
                        <p class="text-center font-bold text-black/70 text-lg">ផ្ដល់ទំនុកចិត្តកំរិតខ្ពស់បំផុត
                            ដោយមានការធានា៥​យ៉ាង។</p>
                    </div>
                </div>



                <div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;"
                    class="relative bg-white overflow-hidden transition-transform duration-300 hover:scale-105  cursor-pointer rounded-md">
                    <div class="absolute inset-0 border-2 border-transparent hover:border-blue-500 
                                transition-all duration-500 animate-border-animation rounded-md"></div>
                    <div class="flex justify-center p-5">
                        <div
                            class="bg-gradient-to-r from-[#500192]/90 to-indigo-600 shadow-md p-2 w-16 h-16 flex items-center justify-center rounded-full text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="lucide lucide-user-check">
                                <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                <circle cx="9" cy="7" r="4" />
                                <polyline points="16 11 18 13 22 9" />
                            </svg>
                        </div>
                    </div>
                    <div class="px-4 pb-6">
                        <p class="text-center font-bold text-black/70 text-lg">ផ្តល់ក្រុមការងារជំនាញ
                            ជួយគាំទ្រអតិថិជនបានគ្រប់កាល:ទេស:។</p>
                    </div>
                </div>


                
                <div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;"
                    class="relative bg-white overflow-hidden transition-transform duration-300 hover:scale-105  cursor-pointer rounded-md">
                    <div class="absolute inset-0 border-2 border-transparent hover:border-blue-500 
                                transition-all duration-500 animate-border-animation rounded-md"></div>
                    <div class="flex justify-center p-5">
                        <div
                            class="bg-gradient-to-r from-[#500192]/90 to-indigo-600 shadow-md p-2 w-16 h-16 flex items-center justify-center rounded-full text-white">
                            <svg xmlns="http://www.w3.org/2000/svg"
                    class="w-[30px]"
                    viewBox="0 0 24 24">
                    <path fill="#FFFFFF"
                      d="M18.72 14.76c.35-.85.54-1.76.54-2.76c0-.72-.11-1.41-.3-2.05c-.65.15-1.33.23-2.04.23A9.07 9.07 0 0 1 9.5 6.34a9.21 9.21 0 0 1-4.73 4.88c-.04.25-.04.52-.04.78A7.27 7.27 0 0 0 12 19.27c1.05 0 2.06-.23 2.97-.64c.57 1.09.83 1.63.81 1.63c-1.64.55-2.91.82-3.78.82c-2.42 0-4.73-.95-6.43-2.66a9.03 9.03 0 0 1-2.24-3.69H2v-4.55h1.09a9.09 9.09 0 0 1 15.33-4.6a8.991 8.991 0 0 1 2.47 4.6H22v4.55h-.06L18.38 18l-5.3-.6v-1.67h4.83zm-9.45-2.99c.3 0 .59.12.8.34a1.136 1.136 0 0 1 0 1.6c-.21.21-.5.33-.8.33c-.63 0-1.14-.5-1.14-1.13c0-.63.51-1.14 1.14-1.14m5.45 0c.63 0 1.13.51 1.13 1.14c0 .63-.5 1.13-1.13 1.13c-.63 0-1.14-.5-1.14-1.13a1.14 1.14 0 0 1 1.14-1.14" />
                  </svg>
                        </div>
                    </div>
                    <div class="px-4 pb-6">
                        <p class="text-center font-bold text-black/70 text-lg">ផ្តល់ជូនការប្រឹក្សាដោយឥតគិតថ្លៃ ជួយឲម្ចាស់អាជីវកម្មបានប្រព័ន្ធដែលឆ្លើយតបតម្រូវការបំផុតហើយចំណាយប្រាក់តិចបំផុត។</p>
                    </div>
                </div>




            </div>
        </div>
    </div>
</template>

<style scoped>
@keyframes border-animation {
    0% {
        border-top-color: indigo;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }

    25% {
        border-right-color: indigo;
        opacity: 0.5;
    }

    50% {
        border-bottom-color: indigo;
        opacity: 0.5;
    }

    75% {
        border-left-color: indigo;
        opacity: 0.5;
    }

    100% {
        border-color: indigo;
        opacity: 0.5;
    }
}

.animate-border-animation:hover {
    animation: border-animation 1s linear forwards;
}
</style>
