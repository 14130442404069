<template>
    <div class="bg-primery1/5 p-[20px] md:p-5 xl:p-0 ">
        <div class="md:w-[100%] lg:w-[90%] xl:w-[1280px] mx-auto  xl:py-5 xl:p-4">

            <div class="xl:space-y-[1px]">
                <!-- Title with bottom border, adjusted to fit the text width -->
                <!-- <div class="text-start">
                    <h2
                        class="inline-block text-primery1 xl:text-[28px] xl:my-3 md:text-[28px] md:my-[12px] my-[14px] lg:text-[24px] lg:my-3 text-[20px] font-bold border-b-4 border-primery1 pb-2">
                        សេវាកម្មអាជីវកម្ម
                    </h2>
                </div> -->

                <!-- Content -->
                <div class="mt-2 font-bold  lg:w-[50%]">
                    <div class="my-3">
                        <p class="text-sm md:text-md lg:text-lg text-gray-600 ">សេវាកម្មអាជីវកម្ម</p>
                        <p class="text-start font-bold text-[20px] md:text-[28px] lg:text-[24px] xl:text-[28px] text-primery1">
                            តើម្ចាស់អាជីវកម្មកំពុងមានការលំបាកគ្រប់គ្រងអាជីវកម្មមែនទេ?</p>
                    </div>
                    <p class="text-start text-black/60 text-md">
                        ឈប់ពិបាកទៀតទៅ ដោះស្រាយបញ្ហារបស់លោកអ្នកជាមួយអ្នកជំនាញបច្ចេកទេសរបស់
                        ម៉ាស្ទឺរអាយធី ស៊ីស្ទឹម​យើងប្រឹក្សាយោបល់ជូនដោយឥតគិតថ្លៃ
                        ដើម្បីជួយលោកអ្នកឲជ្រើសរើសប្រព័ន្ធគ្រប់គ្រងអាជីវកម្មដែលត្រូវនិងតម្រូវការបំផុត និង
                        ចំណាយប្រាក់តិចបំផុត។ 
                        <br>
                        ម៉ាស្ទឺរអាយធី សុីស្ទឹម មានសេវាកម្មដូចជា:
                    </p>
                </div>
            </div>


            <div
                class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-[24px] gap-[20px] md:gap-[30px] xl:grid-cols-4 xl:gap-[30px] py-5 mt-2">
                <div v-for="buss in bussiness" :key="buss">
                    <div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;"
                        class="bg-white h-[350px] p-4 rounded-[6px]  md:h-[340px] lg:h-[350px]  xl:h-[360px] flex flex-col justify-between">
                        <div class="mt-2">
                            <div class="flex justify-center ">
                                <div
                                    class=" lg:w-[90px] h-[80px] w-[80px] lg:h-[90px] xl:w-[90px] xl:h-[90px] rounded-full bg-gradient-to-r from-purple-600 to-indigo-700 flex items-center justify-center">
                                    <img :src="buss.image" class="w-[50px] h-[50px] md:w-[40px] md:h-[40px]" alt="">
                                </div>
                            </div>

                            <div>

                                <h4
                                    class="text-[18px] font-poppins text-black/70 font-bold md:text-[20px] xl:text-[20px] mt-3 lg:text-[18px] line-clamp-2">
                                    {{ buss.name }}</h4>
                                <div v-html="buss.descritpts"
                                    class="text-[14px] mt-2  text-start  md:text-[14px] line-clamp-4 xl:text-[16px] sm:line-clamp-4 text-[#909090] xl:mt-3 md:line-clamp-2 lg:line-clamp-2 xl:line-clamp-2 lg:text-[14px] ">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="bg-[#909090] h-[1.5px] lg:w-[90%] xl:w-[95%]  my-5 mx-auto"></div>
                            <div class="flex justify-end">
                                <!-- <router-link :to="{ name: 'bussinessDetail', params: { id: buss.id } }"> -->
                                <!-- <router-link :to="{ name: 'systemdetails', params: { id: buss.id } }">
                                    <button
                                        class="w-[111px] h-[40px] md:w-[132px] md:text-[14px] lg:text-[16px] xl:text-[18px] md:h-[46px] lg:w-[121px] lg:h-[40px] xl:w-[132px] xl:h-[46px] border-[#E6E6E6] border-2 hover:bg-primery1 hover:text-white shadow transition-all duration-300 ease-in-out rounded-[6px]">Read
                                        more</button>
                                </router-link> -->


                                <router-link :to="{ name: 'systemdetails', params: { id: buss.id } }"
                                    class="btn-hover text-[17px] md:mt-[5px]  md:text-[14px] lg:text-[16px] xl:text-[16px] font-NotoSansKhmer font-bold grow_skew_forward relative z-10 text-white text-2xl py-1 px-10 mt-4  hover:border-blue-500 overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-700 shadow-md">
                                    អានបន្ថែម
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- {{ bussiness }} -->

    <!-- {{ displayData }} -->
</template>

<script>
import { onMounted, ref } from 'vue'
import { collection, query, where, getDocs } from '@firebase/firestore'
import { projectFirestore } from '@/firebase/config'

export default {
    setup() {

        const bussiness = ref(null)

        onMounted(async () => {
            try {
                const q = query(collection(projectFirestore, "products"), where("productdisplay", "==", "normal"));

                const querySnapshot = await getDocs(q);
                const result = []
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    result.push({ id: doc.id, ...doc.data() })
                });
                bussiness.value = result
            }
            catch (err) {
                console.log(err)
            }

        })

        return { bussiness }
    }
}
</script>

<style></style>