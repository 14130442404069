<template>
    <div>
        <div>
            <h1 class="text-center text-primery1 xl:text-[28px] xl:my-2 md:text-[28px] md:my-[12px] my-[14px] lg:text-[24px] lg:my-3 text-[20px] xl:mt-10">
                ឧបករណ៍
            </h1>
        </div>
        <div class="w-[90%] md:w-[90%] lg:w-[90%] xl:w-full mx-auto">
            <div class="p-4 xl:w-[1280px] mx-auto">
                <div
                    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[20px] md:gap-[30px] lg:gap-[24px] xl:gap-[30px] mb-5 w-full">
                    <div v-for="pro in product" :key="pro">
                        <div class="relative bg-white h-full rounded-md cursor-pointer overflow-hidden hover:scale-105 duration-300 transition-all" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;">
                            <div class="absolute inset-0 border-2 border-transparent rounded-md hover:border-indigo-500 transition-all duration-500 animate-border-animation"></div>
                            <div class="p-[16px] gap-6">
                                <div class="flex justify-center">
                                    <img :src="pro.url"
                                    class="h-[100px] w-[100px] md:w-[140px] md:h-[140px] lg:w-[120px] lg:h-[120px] xl:w-[180px] xl:h-[180px] object-contain"
                                    alt="">
                                </div>
                                <div>
                                    <h4
                                        class="font-bold font-poppins md:text-[18px] xl:text-[18px] lg:text-[15px] text-[16px] line-clamp-2">
                                        {{ pro.productName }}
                                    </h4>
                                    <!-- <div v-html="pro.productDesscript"
                                        class="xl:text-[16px] font-poppins prose mt-[12px] md:text-[16px] xl:mt-[16px] lg:text-[#909090] lg:text-[12px] line-clamp-2 text-[14px]">
                                    </div> -->

                                    <div v-html="pro.productDesscript"
                                        class="xl:text-[16px] font-poppins prose mt-[12px] md:text-[16px] xl:mt-[16px] lg:text-[#909090]  lg:text-[12px]  text-[14px]">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              
            </div>
        </div>
    </div>
</template>
<script>
import getCollection from '@/composible/getCollections'
export default {
    setup() {
        const { documents: product } = getCollection("newProduct")
        return { product }
    }
}
</script>
<style scoped>
@keyframes border-animation {
    0% {
        border-top-color: indigo;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }

    25% {
        border-right-color: indigo;
        opacity: 0.8;
    }

    50% {
        border-bottom-color: indigo;
        opacity: 0.8;
    }

    75% {
        border-left-color: indigo;
        opacity: 0.8;
    }

    100% {
        border-color: indigo;
        opacity: 0.8;
    }
}

.animate-border-animation:hover {
    animation: border-animation 1s linear forwards;
}
</style>
