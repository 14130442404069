<template>
  <div
    class="w-full h-full bg-black/20 fixed lg:top-[8rem] md:top-[78px] top-[64px] xl:hidden right-0 select-none text_navbar">
    <div style="box-shadow: 0 20px 9px -22px rgba(0,0,0,0.2) inset;"
      class="text-start font-NotoSansKhmer flex flex-col w-[350px] h-screen items-start -ml-2 gap-[20px] md:gap-[40px] pl-[3rem] md:w-[587px] pt-[30px] bg-background">
      <router-link @click="handleClose" to="/" class="text-heading4  font-bold hover:text-primery1/70 duration-300">
        ទំព័រដើម
      </router-link>

      <div>
        <div>
          <div @click="isDropdownSide = !isDropdownSide"
            class="flex items-center justify-between gap-5 cursor-pointer hover:text-primery1/70">
            <p class="text-heading4  font-bold  duration-300">ផលិតផលនិងសេវ៉ាកម្ម</p>
            <!-- Toggle icon based on isDropdownSide state -->

            <div class=" p-1">
              <svg v-if="!isDropdownSide" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-chevron-down">
                <path d="m6 9 6 6 6-6" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-chevron-up">
                <path d="m18 15-6-6-6 6" />
              </svg>
            </div>
          </div>
        </div>
    
        <div
          :class="isDropdownSide ? 'max-h-[340px] opacity-100 visible block transition-all duration-500 ease-out' : 'max-h-0 opacity-0 invisible overflow-hidden transition-all duration-500 ease-out'">
          <div class="select-none lg:hidden bg-white m-2 text mt-5">
            <div class="flex flex-col pl-3 gap-5">
              <div class="space-y-2">
                <h4 class="font-bold text-[18px]">សេវាកម្មអាជីវកម្ម</h4>
                <div class="space-y-2 flex flex-col">
                  <router-link to="/" class="cursor-pointer hover:text-primery1 font-poppins">Web Design</router-link>
                  <router-link to="/" class="cursor-pointer hover:text-primery1 font-poppins">Business Management
                    System</router-link>
                  <router-link to="/" class="cursor-pointer hover:text-primery1 font-poppins">System
                    Development</router-link>
                  <router-link to="/productsandservices"
                    class="cursor-pointer hover:text-primery1 ">មើលទាំងអស់</router-link>
                </div>
              </div>
              <div class="space-y-2">
                <h4 class="font-bold text-[18px]">ឧបករណ៍</h4>
                <div class="space-y-2 flex flex-col">
                  <router-link to="/" class="cursor-pointer hover:text-primery1 font-poppins">Label</router-link>
                  <router-link to="/" class="cursor-pointer hover:text-primery1 font-poppins">Printer</router-link>
                  <router-link to="/productsandservices"
                    class="cursor-pointer hover:text-primery1">មើលទាំងអស់</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-link @click="handleClose" to="/product"
        class="text-heading4  font-bold hover:text-primery1/70 duration-300">
        ឧបករណ៍
      </router-link>
      <router-link @click="handleClose" to="/aboutUs"
        class="text-heading4  font-bold hover:text-primery1/70 duration-300">
        អំពីពួកយើង
      </router-link>
      <router-link @click="handleClose" to="/contact"
        class="text-heading4  font-bold hover:text-primery1/70 duration-300">
        ទំនាក់ទំនង
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup(props, { emit }) {
    const isDropdownSide = ref(false);

    const handleClose = () => {
      emit("close");
    };

    return { handleClose, isDropdownSide };
  },
};
</script>

<style scoped></style>
