<template>
  <div>
    <!-- <div v-motion :initial="{
      opacity: 0,
    }" :enter="{

      opacity: 1,
      transition: {
        duration: 500,
        ease: 'easeIn',
      },
    }"
      class="w-full lg:w-full xl:w-full lg:h-[666px] xl:h-[870px] md:h-[698px] bg-primery1 h-screen relative p-2 lg:flex xl:flex md:flex hidden overflow-hidden">
      <div
        class="xl:w-[100%] xl:mx-auto lg:w-[70%]  md:w-[70%] w-full h-full xl:flex xl:flex-col lg:flex md:flex items-center justify-center">
        <div class="xl:w-[1280px] md:w-[70%] xl:mx-auto  lg:-ml-[8rem] md:-ml-32 ml-0 ">
          <div class="">

         
          </div>
          <div class="">
            <div class="font-NotoSansKhmer md:-mt-[10rem] xl:p-5  ">
              <h2 class="lg:text-[30px] xl:text-[35px] md:text-[35px] font-bold text-background md:mt-[64px]">
                ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម
              </h2>
              <p class="text-white text-[28px] md:mt-[40px] xl:text-[24px] lg:text-[20px]">ពួកយើងជួយអ្នកឲ</p>
              <h2 class="text-[45px] font-bold text-background md:mt-5 lg:text-[35px] xl:text-[45px]">
                គ្រប់គ្រង់អាជីវកម្ម​
              </h2>
              <h2 class="text-[45px]   lg:text-[35px] xl:text-[45px] font-bold text-background">
                របស់អ្នកងាយស្រួល
              </h2>
              <button @click="handleGogoContact"
                class="px-[24px] md:mt-[81px] font-NotoSansKhmer mt-8 active:bg-accent/70 py-[12px] duration-300 hover:bg-opacity-50 bg-[#FFD74A]  font-semibold text-heading3 rounded-full">
                ទាក់ទងមកពួកយើង
              </button>
            </div>
          </div>

        </div>
      </div>
      <div
        class="xl:w-[1280px] lg:w-[1290px]  md:w-[830px] md:h-[770px]  xl:h-[1183px] lg:h-[976px] xl:rounded-[100%] lg:rounded-[100%] md:rounded-[100%]  border-[14px] border-white absolute xl:-top-52 xl:-right-[30rem] md:-right-[28rem] md:top-[-5%] lg:-right-[60%] lg:-top-36">
        <img class="w-full h-full object-cover rounded-[100%]" src="../assets/ImageCover.jpg" alt="" />
      </div>
    </div> -->
<!--     
    <div
      class="w-full flex flex-col justify-end lg:hidden xl:hidden md:hidden sm:h-[1060px] h-[836px] bg-primery1 relative overflow-hidden">
      <div
        class="h-[559px] w-[607px] sm:w-[800px] sm:h-[760px] absolute rounded-[100%] sm:ml-[5rem] left-4 overflow-hidden border-[14px] border-white -top-[7rem]">
        <img class="w-full h-full object-cover rounded-[100%]" src="../assets/ImageCover.jpg" alt="" />
      </div>
      <div class="h-full flex flex-col justify-center font-NotoSansKhmer text-center">
        <div class="mb-8 md:ml-20 mt-[26rem] sm:mt-[40rem]">
          <p class="text-white text-heading2_Mobile">ពួកយើងជួយអ្នកឲ</p>
          <h2 class="text-[35px] font-bold text-background">
            គ្រប់គ្រង់អាជីវកម្ម​
          </h2>
          <h2 class="text-[35px] font-bold text-background">
            របស់អ្នកងាយស្រួល
          </h2>

    
          <p class=" text-[#FFD74A] text-[18px] py-1 tracking-wide mt-[24px]">
            ប្រឹក្សា និងដោះស្រាយ ដើម្បីរីកចម្រើនជាមួយគ្នា
          </p>
          <button @click="handleGogoContact"
            class="w-[331px] h-[44px]   duration-300 mt-[24px] hover:bg-opacity-70 bg-[#FFD74A] font-semibold text-xl rounded-full">
            ទាក់ទងមកពួកយើង
          </button>
        </div>
      </div>
    </div> -->
    <!-- out standing -->

    <div>
      <BannerComponent/>
    </div>
    <div>
      <WhyChooseMasterComponent/>
    </div>
    <div>
      <BussinesServiceComponentsVue />
    </div>


    <div
  
    >
      <HardwareComponentsVue />
    </div>





    <!-- FAQS -->
    <div class="w-full md:hidden mt-8 hidden lg:hidden xl:hidden  ">
      <h2
        class="lg:text-heading2 md:text-start font-semibold xl:text-heading2 md:text-heading2 text-heading4 font-NotoSansKhmer lg:mt-0 xl:mt-0 md:mt-2 mt-0 lg:ml-0 xl:ml-0 md:ml-4 ml-4">
        សំណួរ ចម្លើយ  
      </h2>


    </div>




    <div>
      <PartnerComponentsVue />
    </div>

 

    <div>
      <QuestionAndAnswerComponentsVue/>
    </div>

  


    <div>
      <SocialMediaVue />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { getCollectionQuery } from "@/composible/getCollection";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { useRouter } from "vue-router";
import SocialMediaVue from './SocialMedia.vue';
import BussinesServiceComponentsVue from '@/components/BussinesServiceComponents.vue'
import HardwareComponentsVue from '@/components/HardwareComponents.vue';
import PartnerComponentsVue from '@/components/PartnerComponents.vue'
import QuestionAndAnswerComponentsVue from '@/components/QuestionAndAnswerComponents.vue'
import BannerComponent from "@/components/BannerComponent.vue";
import WhyChooseMasterComponent from "@/components/WhyChooseMasterComponent.vue";
export default {
  components: {
    Accordion,
    AccordionTab,
    SocialMediaVue,
    BussinesServiceComponentsVue,
    WhyChooseMasterComponent,
    HardwareComponentsVue,
    PartnerComponentsVue,
    QuestionAndAnswerComponentsVue,
    BannerComponent
  },
  setup() {
    const router = useRouter();
    const dataitem = ref([]);
    const products = ref([]);

    const isDropdown = ref(false)


    const getData = async () => {
      try {
        await getCollectionQuery(
          "FAQ",
          [],
          (data) => {
            dataitem.value = data;
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    onMounted(() => {
      getData();
      getDataProduct();
      getDataPartner();
    });

    const getDataProduct = async () => {
      try {
        await getCollectionQuery(
          "products",
          [],
          (data) => {
            // Filter products where productdisplay is outstanding and truncate the descriptions
            products.value = data
              .filter((item) => item.productdisplay === "outstanding")
              .map((item) => ({
                ...item,
                description: item.description
                  ? truncateDescription(item.description)
                  : "",
              }));
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    let sortedbyASC = ref(true);

    const sortList = (sortBy) => {
      if (sortedbyASC.value) {
        products.value.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        sortedbyASC.value = false;
      } else {
        products.value.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        sortedbyASC.value = true;
      }
    };

    const itemsPerPage = ref(3);
    const currentPage = ref(1);

    const totalPages = computed(() => {
      const totalItems = filteredItems.value.length;
      const itemsPerPageValue = itemsPerPage.value; // Use a different name for the constant variable
      return Math.ceil(totalItems / itemsPerPageValue);
    });

    const currentPageItems = computed(() => {
      const startIndex = (currentPage.value - 1) * itemsPerPage.value;
      const endIndex = startIndex + itemsPerPage.value;
      return filteredItems.value.slice(startIndex, endIndex);
    });

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value += 1;
      }
    };

    const searchQuery = ref("");
    const filteredItems = computed(() => {
      const query = searchQuery.value.toLowerCase();
      return products.value.filter((item) => {
        if ((item && item.name) || item.descritpts) {
          return (
            item.name.toLowerCase().includes(query) ||
            item.descritpts.toLowerCase().includes(query)
          );
        }
        return false;
      });
    });
    const itemsPerPageOptions = ref([20, 30, 40, 50]);

    // Update pagination when items per page changes
    const updatePagination = () => {
      currentPage.value = 1; // Reset to first page when items per page changes
    };
    const goToPage = (pageNumber) => {
      currentPage.value = pageNumber;
    };

    const truncateDescription = (description) => {
      const words = description.split(" ");
      if (words.length > 20) {
        return words.slice(0, 20).join(" ") + " ...";
      } else {
        return description;
      }
    };

    const partner = ref([]);
    const getDataPartner = async () => {
      try {
        await getCollectionQuery(
          "partner",
          [],
          (data) => {
            partner.value = data;
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    const handleGogoContact = () => {
      router.push({ name: "contact" });
    };
    return {
      dataitem,
      products,
      handleGogoContact,
      partner,
      truncateDescription,
      sortList,
      currentPageItems,
      currentPage,
      totalPages,
      prevPage,
      nextPage,
      filteredItems,
      searchQuery,
      itemsPerPage,
      itemsPerPageOptions,
      updatePagination,
      goToPage,
      isDropdown,
      type: " border",
    };
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

/* 
::-webkit-scrollbar-thumb:vertical {
  background: #510192b9;
  border-radius: 10px;
  margin-left: 20px;
  transition: 3s;

} */

/*
  Enter and leave animations can use different
  durations and timing functions.
*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>
